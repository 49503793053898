@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital@0;1&display=swap');

@font-face {
  font-family: 'dumbledor__kernedregular';
  src: url('./style/fonts/DumbledorKERNED02-Regular.woff2') format('woff2'),
       url('./style/fonts/DumbledorKERNED02-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('./style/fonts/TradeGothicLTStd.woff2') format('woff2'),
      url('./style/fonts/TradeGothicLTStd.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('./style/fonts/TradeGothicLTStd-Obl.woff2') format('woff2'),
      url('./style/fonts/TradeGothicLTStd-Obl.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('./style/fonts/TradeGothicLTStd-Bd2Obl.woff2') format('woff2'),
      url('./style/fonts/TradeGothicLTStd-Bd2Obl.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic LT Std';
  src: url('./style/fonts/TradeGothicLTStd-Bd2.woff2') format('woff2'),
      url('./style/fonts/TradeGothicLTStd-Bd2.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic LT Std Condensed';
  src: url('./style/fonts/TradeGothicLTStd-Cn18.woff2') format('woff2'),
      url('./style/fonts/TradeGothicLTStd-Cn18.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}